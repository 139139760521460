<template>
  <div
   v-if="showS"
    style="
      min-height: 200px;
      width: 100%;
      background: rgb(239 239 239);
      color: rgb(86 86 86);
    "
  >
    <div class="footer common-width" v-if="type == 'bianyi'">
      <ul>
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组
            | 系统能力培养研究项目发起高校</span
          >
        </li>
        <li>
          <span>承办单位：</span
          ><span
            >
              杭州电子科技大学
           </span
          >
        </li>
        <li>
          <span>协办单位：</span
          ><span
            >华为技术有限公司 |  机械工业出版社 | 教育部编译课程虚拟教研室

          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>赞助单位：</span
          ><span
            ><a href="https://www.huawei.com/" target="_blank">
              华为技术有限公司
            </a>
          </span>
        </li>
        <!-- <li>
          <span>媒体支持：</span
          ><span
            ><a href="https://www.csdn.net/" target="_blank"> CSDN </a>
          
          </span>
        </li> -->

        <li>
          <span style="float: left">评测平台与官网：</span
          ><span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              希冀计算机课程一体化支撑平台
            </a>
            |
            <a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <!-- <li>
          <span
            >
          </span>
        </li> -->

        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
    </div>
    <div class="footer common-width" v-else-if="type == 'shujuku'">
      <ul>
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
          >
     

        </li>
        <!-- <li>
          <span>承办单位：</span
          ><span
            ><a href="https://www.sysu.edu.cn/" target="_blank">
              中山大学
            </a></span
          >
        </li> -->
        <li>
          <span style="float: left" >协办单位：</span
          ><span style="float: left; width: 80%"
            >教育部“101 计划”数据库系统课程虚拟教研室 | 教育部-华为“智能基座”数据库课程虚拟教研室 （中国人民大学） |  机械工业出版社  | 希冀平台

          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>赞助单位：</span
          ><span
            ><a href="https://www.kingbase.com.cn/" target="_blank">
              北京人大金仓信息技术股份有限公司
            </a>
            |
            <a
              href="https://market.cloud.tencent.com/stores/1255389563"
              target="_blank"
            >
              腾讯云计算（北京）有限责任公司
            </a>
         
          </span>
        </li>
        <li>
          <span>技术支持：</span
          ><span
            >希冀信息类专业教学实践一体化平台
          </span>
        </li>

        <li>
          <span style="float: left">评测平台与官网：</span
          ><span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              希冀计算机课程一体化支撑平台
            </a>
            |
            <a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <!-- <li>
          <span
            >
          </span>
        </li> -->

        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
    </div>
    <div class="footer common-width" v-else-if="type == 'shuguang'">
      <ul>
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
          >
     

        </li>
        <li>
          <span>承办单位：</span
          ><span
            >曙光信息产业股份有限公司  |  天津大学
</span
          >
        </li>
        <li>
          <span style="float: left" >协办单位：</span
          ><span style="float: left; width: 80%"
            >机械工业出版社    希冀平台


          </span>
        </li>
      </ul>
      <ul>
        <!-- <li>
          <span>赞助单位：</span
          ><span
            ><a href="https://www.kingbase.com.cn/" target="_blank">
              北京人大金仓信息技术股份有限公司
            </a>
            |
            <a
              href="https://market.cloud.tencent.com/stores/1255389563"
              target="_blank"
            >
              腾讯云计算（北京）有限责任公司
            </a>
         
          </span>
        </li> -->
        <li>
          <span>技术支持：</span
          ><span
            >希冀平台
          </span>
        </li>

        <li>
          <span style="float: left">评测平台与官网：</span
          ><span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              希冀计算机课程一体化支撑平台
            </a>
            |
            <a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <!-- <li>
          <span
            >
          </span>
        </li> -->

        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
    </div>
    <div class="footer common-width" v-else-if="type == 'xb'">
      <ul style="width:80%">
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
          >
        </li>
        <li>
          <span>承办单位：</span
          ><span>
     
              兰州大学

           </span
          >
        </li>
        <li>
          <span style="float: left">协办单位：</span
          ><span style="float: left; width: 80%">
         
              新疆大学 | 宁夏大学 |  青海大学 | 内蒙古师范大学

            
           </span
          >
        </li>
        <li>
          <span style="float: left">大赛官网：</span>
          <span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
     
    </div>
    <div class="footer common-width" v-else-if="type == 'mi'">
      <ul style="width:80%">
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 武汉大学</span
          >
      

        </li>
        <li>
          <span>承办单位：</span
          ><span>
     
            武汉大学

           </span
          >
        </li>
        <li>
          <span style="float: left">协办单位：</span
          ><span style="float: left; width: 80%">
         
            小米科技有限责任公司  |  机械工业出版社

            
           </span
          >
        </li>
        <li>
          <span style="float: left">大赛官网：</span>
          <span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
     
    </div>
 
    <div class="footer common-width" v-else-if="type == 'oceanbase'">
      <ul style="width:80%">
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
          >
        </li>
        <li>
          <span>承办单位：</span
          ><span>
     
            OceanBase

           </span
          >
        </li>
        <li>
          <span style="float: left">协办单位：</span
          ><span style="float: left; width: 80%">
         
            蚂蚁技术研究院 | 机械工业出版社 | 希冀平台 | 墨天轮

            
           </span
          >
        </li>
        <li>
          <span style="float: left">大赛官网：</span>
          <span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
     
    </div>
    <div class="footer common-width" v-else>
      <ul>
        <li>
          <span style="float: left">主办单位：</span
          ><span style="float: left; width: 80%"
            ><a href="http://www.creacu.org.cn/#/home" target="_blank">
              全国高等学校计算机教育研究会
            </a>
            | 系统能力培养研究专家组 | 系统能力培养研究项目发起高校</span
          >
        </li>
        <li>
          <span>承办单位：</span
          ><span
            ><a href="https://www.hdu.edu.cn/main.htm" target="_blank">
              杭州电子科技大学
            </a></span
          >
        </li>
        <li>
          <span style="float: left">协办单位：</span
          ><span style="float: left; width: 80%">
            <a href="http://tcss.ccf.org.cn/" target="_blank">
              中国计算机学会系统软件专委会
            </a>
            |
            <!-- <a href="http://www.ict.ac.cn/" target="_blank">
              中国科学院计算技术研究所
            </a> | 中国科学院软件研究所 |  -->
            <a href="http://www.hzbook.com/" target="_blank">
              机械工业出版社
            </a></span
          >
        </li>
        <li>
          <span style="float: left">大赛官网：</span>
          <span style="float: left; width: 80%"
            ><a href="https://www.educg.net.cn/" target="_blank">
              郑州云海科技有限公司
            </a>
            |
            <a href="https://www.eduxiji.com/" target="_blank">
              阔思格睿网络科技（北京）有限责任公司
            </a>
          </span>
        </li>
        <li>
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              豫ICP备18015569号 | 京ICP备17065346号
            </a>
          </span>
        </li>
        <li>
          <img
            src="../images/gongan.png"
            style="vertical-align: text-bottom"
          /><span
            ><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"
              target="_blank"
            >
              豫公网安备 41010702002654号
            </a>
          </span>
        </li>
      </ul>
      <ul>
        <li>赞助单位</li>
        <li>
          <span>钻石赞助：</span
          ><span
            ><a href="https://www.huawei.com/" target="_blank">
              华为技术有限公司
            </a>
            | <a href="http://www.kylinos.cn/" target="_blank"> 麒麟软件</a>
          </span>
        </li>
        <li>
          <span>金牌赞助：</span
          ><span >
            <!-- <a href="https://openanolis.cn/" target="_blank">
              龙蜥社区
            </a>
            | <a href="http://www.ucas.com.cn/html/about/intro/" target="_blank"> 国科环宇</a>  -->

            <a href="http://www.loongson.cn/" target="_blank"> 龙芯中科</a> |
            <a href="https://www.antgroup.com/" target="_blank"> 蚂蚁集团</a> |
            <a href="https://www.oppo.com/cn/smartphones/" target="_blank">
              OPPO </a
            >| <a href="https://www.vivo.com.cn/" target="_blank"> vivo </a>|
            <a href="https://www.mi.com/" target="_blank"> 小米 </a>
      
            </span>
        </li>
        <li>
          <span>银牌赞助：</span
          ><span>
           
            <a href="https://openanolis.cn/" target="_blank"> 龙蜥社区 </a>
            
            </span>
        </li>
        <li>
          <span>铜牌赞助：</span
          ><span>
            <a href="https://ucas.com.cn/" target="_blank"> 国科环宇 </a> |
            
            <a href="https://www.phytium.com.cn/" target="_blank"> 飞腾 </a>
            
          </span>
        </li>
        <li>
          <span>硬件支持：</span
          ><span>
         
    
            <a href="https://www.phytium.com.cn/" target="_blank"> 飞腾 </a> | 黑芝麻  | 嘉楠 | 龙芯中科 | 平头哥 | 赛昉科技 | 算能 | 中科院软件所 
            
            
          </span>
        </li>

        <!-- <li>
          <span>开发板赞助：</span
          ><span>
            北京算能科技有限公司 | 嘉楠科技 | 赛昉科技 | 博流智能 | 平头哥半导体
            | 矽速科技
          </span>
        </li> -->
        <li>
          <span>媒体支持：</span
          ><span
            ><a href="https://www.csdn.net/" target="_blank"> CSDN </a>
            <!-- |
            <a href="https://www.geekbang.org/" target="_blank"> Geekbang </a>
            | <a href="https://www.infoq.cn/" target="_blank"> InfoQ </a> -->
          </span>
        </li>
        <li>
          <span>技术支持：</span
          ><span
            >
            <a href="https://www.educg.net/" target="_blank">
              希冀信息类专业教学实践一体化平台
            </a>  |  军事科学院
          </span>
        </li>

        <li>
          <span>评测平台：</span
          ><span
            ><a href="https://www.educg.net.cn/" target="_blank">
              希冀计算机课程一体化支撑平台
            </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import { set } from 'core-js/core/dict';

export default {
  data() {
    return {
      type: "xitong",
      showS:false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showS=true
      
    }, 100);
    debugger
    // if (window.localStorage.getItem("anjing-parent") == "编译系统大赛") {
    //   // 编辑赛
    //   this.type = "bianyi";
    // } else if (
    //   window.localStorage.getItem("anjing-parent") == "数据库系统大赛"
    // ) {
    //   // 编辑赛
    //   this.type = "shujuku";
    // } 
    // else if(  window.localStorage.getItem("anjing-parent") == "操作系统大赛" && window.localStorage.getItem("anjing-stitleNew").indexOf('西北') !=-1 ){
    //   //西北
    //   this.type = "xb";


    // }
    
    
    // else {
    //   this.type = "xitong1";
    // }
    console.log(this.type)
  },
  watch:{
    showS(n,o){
      console.log(o,n)
      if (window.localStorage.getItem("anjing-parent") == "编译系统大赛") {
      // 编辑赛
      this.type = "bianyi";
    } else if (
      window.localStorage.getItem("anjing-parent") == "数据库系统大赛"
    ) {
      // 编辑赛
      this.type = "shujuku";
    } 
    else if(  window.localStorage.getItem("anjing-parent") == "操作系统大赛" && window.localStorage.getItem("anjing-stitleNew").indexOf('西北') !=-1 ){
      //西北
      this.type = "xb";


    }
    else if(  window.localStorage.getItem("anjing-parent") == "小米大赛" ){
      //西北
      this.type = "mi";


    }
    else if(  window.localStorage.getItem("anjing-parent") == "曙光大赛" ){
      //西北
      this.type = "shuguang";


    }
    else if(  window.localStorage.getItem("anjing-parent") == "oceanbase大赛" ){
      //西北
      this.type = "oceanbase";


    }
    
    
    else {
      this.type = "xitong1";
    }

    }
  },  
};
</script>
<style scoped>
ul,
li {
  list-style: none;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px 0;
}
.footer ul li {
  margin-bottom: 10px;
  overflow: hidden;
}
.footer ul {
  padding: 0 10px;
  margin: 0;
  width: 50%;
}
@media (max-width: 800px) {
  .footer ul {
    width: 100%;
  }
}
</style>